<template>
  <div>
    <b-card class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title"></div>
        <div class="card-toolbar">
          <v-dialog v-model="dialog" max-width="900px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="btn-success"
                class="btn btn-light-success font-weight-bolder mr-2 mt-3"
                v-bind="attrs"
                v-on="on"
              >
                <i class="flaticon2-plus"></i>
                {{ $t("ADD_NEW") }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ $t("HEALTH_CARD") }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <FormDatepickerBase
                        :date.sync="editedItem.date_from"
                        :type="'text'"
                        :clearable="true"
                        :i18n="{
                          label: 'HEALTH_CARD_VALID_FROM',
                          placeholder: $t('HEALTH_CARD_VALID_FROM')
                        }"
                      ></FormDatepickerBase>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <FormDatepickerBase
                        :date.sync="editedItem.date_to"
                        :type="'text'"
                        :clearable="true"
                        :i18n="{
                          label: 'HEALTH_CARD_VALID',
                          placeholder: $t('HEALTH_CARD_VALID')
                        }"
                      ></FormDatepickerBase>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.description"
                        label="Description (optional)"
                        hide-details
                        single-line
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save(editedItem)">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this Health Card?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>
      <v-card v-show="!isPageLoading">
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :item-class="expiringHealthCardRow"
          :sort-by="['deleted_at']"
          :sort-desc="false"
          :options.sync="options"
          :items-per-page="totalItems"
          :server-items-length="totalItems"
          :loading="isLoadingHealthStore"
          loading-text="Loading... Please wait"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right'
          }"
          @update:options="getDataFromApi()"
          class="health-cards-user-table"
        >
          <v-divider inset></v-divider>
          <template v-slot:top>
            <SearchTools
              :searchQuery.sync="searchQuery"
              @do-search="doSearch"
              @do-clear="doClear"
            >
              <template v-slot:prepend>
                <b-col>
                  <FormDatepickerBase
                    :type="'text'"
                    :clearable="true"
                    :i18n="{
                      label: 'HEALTH_CARD_VALID_FROM',
                      placeholder: $t('HEALTH_CARD_VALID_FROM')
                    }"
                    :date.sync="filters.dateFrom"
                  ></FormDatepickerBase>
                </b-col>
                <b-col>
                  <FormDatepickerBase
                    :type="'text'"
                    :clearable="true"
                    :i18n="{
                      label: 'HEALTH_CARD_VALID',
                      placeholder: $t('HEALTH_CARD_VALID')
                    }"
                    :date.sync="filters.dateTo"
                  ></FormDatepickerBase>
                </b-col>
              </template>
            </SearchTools>
          </template>
          <template v-slot:item.date_from="{ item }">
            <FormDatepickerBase
              v-if="item.id === editedItem.id"
              :type="'date'"
              :clearable="false"
              :i18n="{}"
              :date.sync="editedItem.date_from"
            ></FormDatepickerBase>
            <span v-else>{{ item.date_from | formatDate }}</span>
          </template>
          <template v-slot:item.date_to="{ item }">
            <FormDatepickerBase
              v-if="item.id === editedItem.id"
              :type="'date'"
              :i18n="{}"
              :clearable="false"
              :date.sync="editedItem.date_to"
            ></FormDatepickerBase>
            <span v-else>{{ item.date_to | formatDate }}</span>
          </template>
          <template v-slot:item.date_till="{ item }">
            {{
              item.date_till < 0
                ? "Expired"
                : item.date_till == 0
                ? "Today"
                : item.date_till + (item.date_till == 1 ? " day" : " days")
            }}
          </template>
          <template v-slot:item.description="{ item }">
            <v-text-field
              v-model="editedItem.description"
              hide-details
              single-line
              label="Description (optional)"
              v-if="item.id === editedItem.id"
            ></v-text-field>
            <span v-else>{{ item.description }}</span>
          </template>
          <template v-slot:item.deleted_at="{ item }">
            <v-chip :color="getColor(item.deleted_at)" small dark pill>
              <v-avatar left>
                <v-icon>{{ iconIsActive(item.deleted_at) }}</v-icon>
              </v-avatar>
              {{ textIsActive(item.deleted_at) }}
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <div v-if="item.deleted_at === ''">
              <div v-if="item.id === editedItem.id">
                <v-icon color="red" class="mr-3" @click="close">
                  mdi-window-close
                </v-icon>
                <v-icon color="green" @click="update(editedItem)">
                  mdi-content-save</v-icon
                >
              </div>
              <div v-else>
                <v-icon color="green" class="mr-3" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon color="red" @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </div>
            </div>
            <div v-else>
              <div v-if="item.id === editedItem.id">
                <v-icon color="red" class="mr-3" @click="close">
                  mdi-window-close
                </v-icon>
                <v-icon color="green" @click="update(editedItem)">
                  mdi-content-save</v-icon
                >
              </div>
              <div v-else>
                <v-icon color="green" class="mr-3" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-card>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
      <template v-slot:footer></template>
    </b-card>
  </div>
</template>
<script>
import {
  DELETE_HEALTH_CARD,
  FETCH_USER_HEALTH_CARDS,
  SAVE_HEALTH_CARD,
  UPDATE_HEALTH_CARD
} from "@/modules/health-card/store/health-card.module";
import { mapGetters } from "vuex";
import FormDatepickerBase from "@/view/content/forms/components/FormDatepickerBase.vue";
import SearchTools from "@/core/components/table/SearchTools.vue";

export default {
  name: "HealthCardTable",
  inheritAttrs: false,
  props: {
    visible: Boolean
  },
  data() {
    return {
      search: "",
      dialog: false,
      dialogDelete: false,
      items: [],
      totalItems: 100,
      searchQuery: "",
      filters: {
        dateFrom: "",
        dateTo: ""
      },
      options: {
        user_id: 1,
        descending: false,
        page: 1,
        itemsPerPage: 10,
        totalItems: 100
      },
      rowsPerPageItems: [10, 20, 30, 40, 50],
      columns: {},
      cancelSource: null,
      headers: [
        {
          text: "Id",
          align: "left",
          value: "id"
        },
        { text: "Date From", value: "date_from", sortable: false },
        { text: "Date To", value: "date_to" },
        { text: "Expire", value: "date_till" },
        { text: "Description", value: "description", sortable: false },
        { text: "Active", value: "deleted_at", sortable: false },
        {
          align: "right",
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "100px"
        }
      ],
      editedItem: {
        id: 0,
        date_from: "",
        date_to: "",
        description: "",
        deleted_at: ""
      },
      defaultItem: {
        id: 0,
        date_from: "",
        date_to: "",
        description: "",
        deleted_at: ""
      }
    };
  },
  components: {
    SearchTools,
    FormDatepickerBase
  },
  mounted() {},
  computed: {
    ...mapGetters(["getHealthCards", "isLoadingHealthCard"]),
    params() {
      return {
        ...this.options,
        query: this.searchQuery,
        dateFrom: this.filters.dateFrom,
        dateTo: this.filters.dateTo
      };
    },
    tableHeaders() {
      let vm = this;
      return vm.headers;
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  methods: {
    expiringHealthCardRow(item) {
      return item.date_till <= item.expiration_alert && item.deleted_at == ""
        ? "text-danger"
        : "";
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item);
    },
    deleteItem(item) {
      this.defaultItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      let vm = this;
      this.$store
        .dispatch(DELETE_HEALTH_CARD, vm.defaultItem.id)
        .then((data) => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          vm.$nextTick(function () {
            vm.defaultItem = Object.assign({}, vm.editedItem);
            this.close();
            this.doSearch();
          });
        })
        .catch((response) => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
            this.$router.push({ name: "list-stores" });
          }
        });
      this.doSearch();
    },
    close() {
      this.dialog = false;
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    save(newItem) {
      let vm = this;

      let payload = {
        user_id: this.$route.params.id,
        date_from: newItem.date_from,
        date_to: newItem.date_to,
        description: newItem.description
      };
      this.$store
        .dispatch(SAVE_HEALTH_CARD, payload)
        .then((data) => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          vm.$nextTick(function () {
            this.close();
            this.doSearch();
          });
        })
        .catch((response) => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
            this.close();
          }
        });
    },
    update(editedItemUpdate) {
      let vm = this;
      let payload = {
        user_id: this.$route.params.id,
        date_from: editedItemUpdate.date_from,
        date_to: editedItemUpdate.date_to,
        description: editedItemUpdate.description
      };

      this.$store
        .dispatch(UPDATE_HEALTH_CARD, {
          id: this.editedItem.id,
          payload: payload
        })
        .then((data) => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          vm.$nextTick(function () {
            this.close();
            this.doSearch();
          });
        })
        .catch((response) => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
            this.close();
          }
        });
    },
    doSearch() {
      let vm = this;
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    doClear() {
      let vm = this;
      vm.searchQuery = "";
      vm.filters.dateTo = "";
      vm.filters.dateFrom = "";
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    getDataFromApi() {
      let vm = this;
      // vm.tableLoading = true;
      //copy current params to modify
      let params = this.params;
      params.user_id = this.$route.params.id;
      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_USER_HEALTH_CARDS, apiParams)
        .then((data) => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function () {
            vm.items = data.data.items;
            vm.totalItems = data.data.totalItemsCount;

            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch((response) => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
          }
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    getColor(active) {
      if (active != "") return "red";
      else return "primary";
    },
    textIsActive(active) {
      if (active != "") return "Inactive";
      else return "Active";
    },
    iconIsActive(active) {
      if (active != "") return "mdi-close-circle-outline";
      else return "mdi-checkbox-marked-circle-outline";
    }
  }
};
</script>
