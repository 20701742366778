<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Edit'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-users' }">{{
            $t("USERS")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("EDIT") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <DeleteButton
            v-on:onDelete="onDelete()"
            v-permission="['users-delete']"
          ></DeleteButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingUser"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <v-tabs
          v-model="tab"
          background-color="white"
          color="primary accent-4"
          centered
          icons-and-text
        >
          <!-- Description Tab-->
          <v-tab href="#description">
            {{ $t("DESCRIPTION") }}
            <v-icon>mdi-note-text</v-icon>
          </v-tab>
          <!-- Stores Tab-->
          <v-tab v-if="!checkIfSelfUser()" href="#stores">
            {{ $t("STORES") }}

            <v-badge
              :value="_.size(form.stores) == 0"
              color="orange"
              content="!"
              overlap
            >
              <v-icon>mdi-home</v-icon>
            </v-badge>
          </v-tab>
          <!-- Health Card Tab-->
          <v-tab href="#health-cards">
            {{ $t("HEALTH_CARDS") }}

            <v-badge color="orange" :value="false" overlap>
              <v-icon>mdi-home</v-icon>
            </v-badge>
          </v-tab>

          <v-tabs-items
            :value="tab"
            :transition="false"
            :reverse-transition="false"
          >
            <!-- Description Tab Data-->
            <v-tab-item
              value="description"
              :transition="false"
              :reverse-transition="false"
            >
              <b-form>
                <FormBuilder
                  v-if="!firstLoader"
                  :form="form"
                  :schemaJson="schemaJson"
                  :v="$v"
                ></FormBuilder>
                <template v-if="!firstLoader && form.isAdmin">
                  <v-divider></v-divider>
                  <b-button
                    v-if="!('password' in form)"
                    variant="outline-success"
                    @click="setNewPassword()"
                  >
                    <v-icon dark>mdi-restart</v-icon>
                    {{ $t("SET_NEW_PASSWORD") }}
                  </b-button>
                  <b-button
                    v-if="'password' in form"
                    variant="outline-warning"
                    @click="noNewPassword()"
                  >
                    <v-icon dark>mdi-close</v-icon>
                    {{ $t("NO_NEW_PASSWORD") }}
                  </b-button>
                  <b-form-row v-if="'password' in form">
                    <b-col>
                      <b-form-group
                        v-bind:id="'password-password-input'"
                        v-bind:label="$t('PASSWORD')"
                        v-bind:label-for="'password-password-input'"
                      >
                        <div class="input-group">
                          <FormInput
                            :id="'password-password-input'"
                            :type="'text'"
                            :name="'password'"
                            :required="'true'"
                            :feedback_id="'password-password-feedback'"
                            :i18n="{}"
                            :validations="{}"
                            :validateState="validateState('password')"
                            v-model="form.password"
                          >
                            <template v-slot:button-append>
                              <div class="input-group-append">
                                <span
                                  @click="generateRandomPassword()"
                                  class="btn btn-primary font-weight-bolder mr-2"
                                >
                                  <v-icon dark>mdi-restart</v-icon>
                                  {{ $t("GENERATE_PASSWORD") }}
                                </span>
                              </div>
                            </template>
                          </FormInput>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                </template>
                <SkeletonLoaderMain :visible="firstLoader"></SkeletonLoaderMain>
                <v-overlay :value="!firstLoader && isLoadingUser">
                  <v-progress-circular
                    indeterminate
                    size="64"
                  ></v-progress-circular>
                </v-overlay>
              </b-form>
            </v-tab-item>
            <!-- Stores Tab Data-->
            <v-tab-item
              v-if="!checkIfSelfUser()"
              value="stores"
              :transition="false"
              :reverse-transition="false"
            >
              <b-row>
                <b-col :lg="12">
                  <CheckboxList
                    :heading="$t('STORES')"
                    :items="stores"
                    :selectedItems.sync="form.stores"
                    :modelName="'stores'"
                    :firstLoader="firstLoader"
                    :checkboxLoader="isLoadingSharedStore"
                    :labelKey="'nameWithCity'"
                    :sm="4"
                  >
                  </CheckboxList>
                  <SkeletonLoaderCheckboxes
                    :visible="firstLoader"
                  ></SkeletonLoaderCheckboxes>
                </b-col>
              </b-row>
            </v-tab-item>
            <!-- Health Card Tab Data-->
            <v-tab-item
              value="health-cards"
              :transition="false"
              :reverse-transition="false"
            >
              <health-card-table></health-card-table>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <DeleteButton
            v-on:onDelete="onDelete()"
            v-permission="['users-delete']"
          ></DeleteButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingUser"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  FETCH_USER,
  UPDATE_USER,
  DELETE_USER
} from "@/modules/user/store/user.module";

//Role Module
import { FETCH_ROLES } from "@/modules/role/store/role.module";

//Stores
import { FETCH_STORES_BASIC } from "@/core/services/store/shared_store.module";

import FormBuilder from "@/view/content/forms/FormBuilder.vue";
import FormInput from "@/view/content/forms/components/FormInput.vue";
import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";
import CheckboxList from "@/view/content/forms/components/CheckboxList.vue";

import { validationMixin } from "vuelidate";

import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

import KTCard from "@/view/content/Card.vue";

import { mapGetters, mapState } from "vuex";
import HealthCardTable from "@/modules/health-card/components/HealthCardTable";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";

export default {
  mixins: [validationMixin, formBuilderMixin, permissionMixin],
  data() {
    return {
      errors: {},
      firstLoader: true,
      user: {},
      roles: [],
      rolesParams: {
        page: 1,
        itemsPerPage: 50,
        query: ""
      },
      trackChangesOptionsTable: {
        page: 1,
        itemsPerPage: 10,
        totalItems: 100
      },
      trackChangesHeaders: [
        { text: "Role", value: "roleName", sortable: true },
        { text: "Hourly Rate", value: "hourlyRate", sortable: true },
        { text: "From Date", value: "fromDate", sortable: true },
        { text: "To Date", value: "toDate", sortable: true }
      ],
      isEdit: true,
      stores: []
    };
  },
  components: {
    HealthCardTable,
    KTCard,
    FormBuilder,
    FormInput,
    SaveButtonDropdown,
    CheckboxList
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Users", route: { name: "list-users" } },
      { title: "Edit User" }
    ]);
    vm.getUser();
    vm.getRoles();
    vm.getStores();
  },
  watch: {},
  computed: {
    ...mapGetters(["isLoadingUser", "getLanguages", "currentUser"]),
    ...mapState({
      errors: (state) => state.auth.errors
    }),
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      }
    },
    schemaJson() {
      return {
        fields: [
          {
            type: "switch",
            groupId: "user-active-group",
            id: "user-active-input",
            inputType: "checkbox",
            required: "required",
            feedback_id: "active-live-feedback",
            colSm: "auto",
            divider: true,
            i18n: {
              label: "ACTIVE",
              placeholder: "ACTIVE",
              validation: "VALID_ACTIVE"
            },
            validations: {
              required: true
            },
            model: "active"
          },
          {
            type: "switch",
            groupId: "user-is_admin-group",
            id: "user-is_admin-input",
            inputType: "checkbox",
            required: "required",
            feedback_id: "is_admin-live-feedback",
            colSm: "auto",
            divider: false,
            i18n: {
              label: "IS_ADMIN",
              placeholder: "IS_ADMIIN",
              validation: "VALID_VALUE"
            },
            validations: {
              required: true
            },
            model: "isAdmin"
          },
          {
            type: "select",
            inputType: "select",
            groupId: "store-role-group",
            id: "store-role-input",
            feedback_id: "role-live-feedback",
            divider: false,
            clearable: false,
            i18n: {
              label: "ROLE",
              placeholder: "ROLE",
              validation: "VALID_ROLE"
            },
            options: this.roles,
            validations: {
              required: true
            },
            hidden:
              !this.form.isAdmin ||
              !this.checkIfUserHasPermission(["roles-show"]) ||
              this.checkIfSelfUser(),
            model: "roleId"
          },
          {
            type: "select",
            inputType: "select",
            groupId: "store-lang-group",
            id: "store-lang-input",
            feedback_id: "lang-live-feedback",
            divider: false,
            clearable: false,
            i18n: {
              label: "LANG",
              placeholder: "LANG",
              validation: "INVALID"
            },
            options: this.getLanguages,
            reduceKey: "lang",
            validations: {
              required: true
            },
            model: "lang"
          },
          {
            type: "input",
            inputType: "text",
            id: "user-email-input",
            groupId: "user-email-group",
            required: "required",
            feedback_id: "user-live-feedback",
            divider: true,
            i18n: {
              label: "EMAIL",
              placeholder: "EMAIL",
              validation: "VALID_EMAIL"
            },
            validations: {},
            translatable: false,
            hidden: !this.form.isAdmin,
            model: "email"
          },
          {
            type: "input",
            inputType: "text",
            id: "user-firstName-input",
            groupId: "user-firstName-group",
            required: "required",
            feedback_id: "user-live-feedback",
            divider: false,
            i18n: {
              label: "FIRST_NAME",
              placeholder: "FIRST_NAME",
              validation: "VALID_FIRST_NAME"
            },
            validations: {
              required: true,
              minLength: 2,
              params: { name: "First Name" }
            },
            translatable: false,
            model: "firstName"
          },
          {
            type: "input",
            inputType: "text",
            id: "user-lastName-input",
            groupId: "user-lastName-group",
            required: "required",
            feedback_id: "user-live-feedback",
            divider: false,
            i18n: {
              label: "LAST_NAME",
              placeholder: "LAST_NAME",
              validation: "VALID_LAST_NAME"
            },
            validations: {
              required: true,
              minLength: 2
            },
            translatable: false,
            model: "lastName"
          },
          {
            type: "datepickerbase",
            inputType: "datepickerbase",
            id: "user-contractDate-input",
            groupId: "user-contractDate-group",
            feedback_id: "user-live-feedback",
            divider: false,
            i18n: {
              label: "CONTRACT_DATE",
              placeholder: "CONTRACT_DATE",
              validation: "VALID_CONTRACT_DATE"
            },
            validations: {
              required: false
            },
            translatable: false,
            model: "contractDate"
          }
        ]
      };
    }
  },
  validations() {
    let vm = this;
    let tmpValidationObject = this.generateFormValidationsOutOfSchemaJson(
      this.schemaJson
    );
    const passwordValidations = {
      required: true,
      minLength: 8
    };
    if ("password" in vm.form) {
      tmpValidationObject.form["password"] =
        vm.setItemValidations(passwordValidations);
    }

    let roleValidations = {
      required: false
    };
    let emailValidations = {
      required: false
    };

    if (vm.form.isAdmin === true) {
      roleValidations = {
        required: true
      };
      emailValidations = {
        required: true
      };
    }

    tmpValidationObject.form["roleId"] = vm.setItemValidations(roleValidations);
    tmpValidationObject.form["email"] = vm.setItemValidations(emailValidations);

    return tmpValidationObject;
  },
  methods: {
    checkIfSelfUser() {
      return this.$route.params.id == this.currentUser.id;
    },
    getUser() {
      let vm = this;
      let id = this.$route.params.id;
      this.$store
        .dispatch(FETCH_USER, id)
        .then((data) => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function () {
            vm.user = data.data;
            this.generateFormOutOfSchemaJson(this.schemaJson, vm.user).then(
              () => {
                vm.$set(vm.form, "stores", vm.user.stores);
                vm.prepareChangesCheck();
              }
            );
          });
        })
        .catch((response) => {
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            this.$router.push({ name: "list-users" });
          }
        });
    },
    getRoles(search, loading = function () {}) {
      let vm = this;
      let params = this.rolesParams;
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_ROLES, apiParams)
        .then((data) => {
          vm.roles = data.data.items;
          vm.totalRoles = data.data.totalItemsCount;
        })
        .catch((response) => {
          console.log(response);
        })
        .finally(() => {
          loading(false);
        });
    },
    getStores() {
      let vm = this;
      vm.storesLoader = true;
      this.$store
        .dispatch(FETCH_STORES_BASIC)
        .then((data) => {
          vm.stores = data.data;
          // vm.form.stores = vm.supplier.stores;
        })
        .catch((response) => {
          console.log(response);
        })
        .finally(() => {
          vm.storesLoader = false;
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    onSave(next) {
      let vm = this;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly"
        });
        return;
      }
      //remove admin inputs if the user is not admin
      let filteredForm = {};
      filteredForm = { ...vm.form };
      if (vm.form.isAdmin == false) {
        filteredForm.email = undefined;
        filteredForm.roleId = undefined;
        filteredForm.password = undefined;
      }
      //<--remove admin inputs if the user is not admin
      this.$store
        .dispatch(UPDATE_USER, { id: vm.user.id, payload: filteredForm })
        .then((data) => {
          vm.errors = data.data.errors;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });

          vm.prepareChangesCheck().then(() => {
            // let itemEdit = data.data;
            if (next == "continue") {
              //New
              // this.$router.push({
              //   name: "edit-user",
              //   params: { id: itemEdit.id }
              // });
              vm.user = data.data;
              this.generateFormOutOfSchemaJson(this.schemaJson, vm.user).then(
                () => {
                  vm.prepareChangesCheck();
                }
              );
            }
            if (next == "new") {
              //New
              this.$router.push({ name: "add-user" });
            }
            if (next == "exit") {
              this.$router.push({ name: "list-users" });
            }
          });
        })
        .catch(function (response) {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: "Error",
            text: response.data.message
          });
        });
    },
    onDelete() {
      let vm = this;
      let message = vm.$t("PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function () {
          vm.$store
            .dispatch(DELETE_USER, vm.user.id)
            .then((data) => {
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });

              vm.prepareChangesCheck().then(() => {
                vm.$router.push({ name: "list-users" });
              });
            })
            .catch(function (response) {
              vm.$notify({
                group: "notify",
                type: "error",
                title: "Error",
                text: response.data.message
              });
            });
        })
        .catch(function () {});
    },
    setNewPassword() {
      let vm = this;
      vm.$set(vm.form, "password", "");
    },
    noNewPassword() {
      let vm = this;
      vm.$delete(vm.form, "password");
    },
    generateRandomPassword() {
      let vm = this;
      var randomString = Math.random().toString(36).slice(-8);
      vm.form.password = randomString;
    },
    resetVForm() {
      this.$v.$reset();
    }
  }
};
</script>
